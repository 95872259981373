<template>
  <div>
    <div>
      <div class="flex items-center">
        <form class="w-80 relative sm:mb-0" @click.prevent="clearVariants">
          <button class="absolute left-0 top-0 mt-2 pt-px ml-4 z-1">
            <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5" />
          </button>

          <input
            type="text"
            class="bg-white w-full rounded-full pl-12 py-2 outline-none"
            v-model="search"
            placeholder="Search..."
            @keyup.esc="(search = null), clearVariants()"
          />
        </form>
        <div class="mx-4">
          <DualButton :options="buttonOptions" @execute="handleButtonMethods($event)" />
        </div>
        <div class="flex items-center">
          <label for="sort" class="mx-2"> Sort: </label>
          <select
            name="sort"
            id="sort"
            v-model="sort"
            class="shadow focus:border-blue-500 sm:text-sm border-blue-300 rounded-md"
            @change="clearVariants()"
          >
            <option value="productNum">Product Number</option>
            <option value="productName">Product Name</option>
            <option value="qbID">Quickbooks ID</option>
          </select>
        </div>
        <!-- <div class="relative flex items-center">
          <div class="flex h-6 items-center">
            <input
              v-model="unassociated"
              @change="retrieveData()"
              type="checkbox"
              class="h-4 w-4 rounded border border-gray-600 focus:ring-indigo-600"
            />
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="comments" class="font-medium text-gray-900">Unassociated</label>
            <p id="comments-description" class="text-gray-500">
              returns the variants that don't have a product
            </p>
          </div>
        </div>
        <div class="relative flex items-center">
          <div class="flex h-6 items-center">
            <input
              v-model="deepClear"
              @change="retrieveData()"
              type="checkbox"
              class="h-4 w-4 rounded border border-gray-600 focus:ring-indigo-600"
            />
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="comments" class="font-medium text-gray-900">clear</label>
            <p id="comments-description" class="text-gray-500">
              verifies every variant to ensure the product it is associated with exists. This will
              take a while to run
            </p>
          </div>
        </div> -->
      </div>

      <div class="relative border rounded bg-white my-4 min-h-screen" :key="search">
        <div class="grid grid-cols-11 bg-blue-100 font-semibold py-2 px-4">
          <div></div>
          <div>Number</div>
          <div>Name</div>
          <div>Bin</div>
          <div>Quickbooks ID</div>
          <div>Stock</div>
          <div>Min</div>
          <div>MSRP</div>
          <div>Cost</div>
          <div>Published</div>
        </div>
        <div v-if="loading"><Loading /></div>

        <div v-else class="p-4">
          <div
            v-for="variant in variants"
            :key="variant._id"
            class="border-b py-1 grid grid-cols-11 items-center"
          >
            <label
              :for="variant._id"
              class="grid grid-cols-3 row-span-2 items-center cursor-pointer"
            >
              <input
                type="checkbox"
                class="border border-gray-700 rounded"
                name=""
                :id="variant._id"
                @change="mutateSelected(variant)"
              />
              <div class="w-16 h-16 overflow-hidden col-span-2 rounded border border-gray-300">
                <img :src="variant.thumbnail" />
              </div>
            </label>
            <div>
              {{ variant.productNum }}
            </div>
            <div>
              <router-link :to="`/admin/products/${variant.product_id}`" v-if="variant.product_id">
                {{ variant.product_name }}
              </router-link>
              <span v-else>
                {{ variant.product_name }}
              </span>
            </div>
            <div>
              {{ variant.binLocation }}
            </div>
            <div>
              {{ variant.quickbooksId }}
            </div>
            <div>
              {{ variant.stock }}
            </div>
            <div>
              {{ variant.min }}
            </div>
            <div>
              {{ variant.msrp }}
            </div>
            <div>
              {{ variant.cost }}
            </div>
            <div>
              <div>
                {{ variant.publish }}
              </div>
            </div>
            <div>
              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  @click="addToProduct(variant)"
                  type="button"
                  class="relative inline-flex items-center px-4 py-2 rounded-l-md border text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 text-green-700 bg-green-100 hover:bg-green-200"
                >
                  Add
                </button>
                <button
                  type="button"
                  @click="deleteVariant(variant)"
                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:border-red-500 text-red-700 bg-red-100 hover:bg-red-200 rounded-r-md"
                >
                  Delete
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-popup v-if="showPopup" @close="showPopup = false" title="Save variant to product">
      <div>Which product do you wish to save {{ variant.productNum }} to?</div>
      <div class="my-6">
        <label for="product_id" class="block text-sm font-medium text-gray-700">Product ID</label>
        <div class="mt-1">
          <input
            type="text"
            name="product_id"
            v-model="product_id"
            class="styled shadow-sm focus:border-primary block w-full sm:text-sm border-secondary rounded-md"
          />
        </div>
      </div>
      <div class="text-center mt-6">
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            @click="saveToProduct()"
            type="button"
            class="relative inline-flex items-center px-4 py-2 rounded-l-md border text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 text-green-700 bg-green-100 hover:bg-green-200"
          >
            Save
          </button>
          <button
            type="button"
            @click="showPopup = false"
            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:border-red-500 text-red-700 bg-red-100 hover:bg-red-200 rounded-r-md"
          >
            Cancel
          </button>
        </span>
      </div>
    </modal-popup>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue';
import ModalPopup from '@/components/shared/DesktopModal.vue';
import DualButton from '@/components/shared/DualButton';

export default {
  data: function () {
    return {
      variants: [],
      selectedVariants: [],
      showPopup: false,
      product_id: '',
      variant: {},
      unassociated: false,
      deepClear: false,
      buttonOptions: [
        {
          name: 'Export selected',
          text: 'Export the selected variants',
          buttonMethod: 'export',
        },
        {
          name: 'Export all',
          text: 'Export all variants',
          buttonMethod: 'exportAll',
        },
        {
          name: 'Retrieve unassociated',
          text:
            'This will return all the variants that do not have a product_id associated with them. Feel free to delete these products. ',
          buttonMethod: 'retrieveUnassociated',
        },
        {
          name: 'Deep clean variants',
          text:
            'This will go through every single variant to ensure that it is associated with a valid product. ',
          buttonMethod: 'cleanVariants',
        },
      ],
    };
  },
  props: {},
  computed: {
    sort: {
      set(val) {
        this.$store.commit('variants/setSort', val);
      },
      get() {
        return this.$store.getters['variants/getSort'];
      },
    },
    search: {
      set(val) {
        this.$store.commit('variants/setSearch', val);
      },
      get() {
        return this.$store.getters['variants/getSearch'];
      },
    },
    loading() {
      return this.$store.getters['variants/getLoading'];
    },
  },
  watch: {
    showPopup() {
      if (!this.showPopup) {
        this.variant = {};
        this.product_id = '';
      }
    },
  },
  methods: {
    saveToProduct() {
      if (!this.product_id) {
        this.$store.commit('setError', {
          message: 'No product ID',
          color: 'red',
        });
        return;
      }
      if (!this.variant._id) {
        this.$store.commit('setError', {
          message: 'No variant ID',
          color: 'red',
        });
        return;
      }
      this.$store.dispatch('variants/saveToProduct', {
        variant_id: this.variant._id,
        product_id: this.product_id,
      });
    },
    mutateSelected(variant) {
      const checkbox = document.getElementById(variant._id);
      if (!checkbox.checked) {
        checkbox.checked = false;
        this.selectedVariants = this.selectedVariants.filter((el) => {
          return el._id !== variant._id;
        });
      } else {
        checkbox.checked = true;
        this.selectedVariants.push(variant);
      }
    },
    clearVariants() {
      this.variants = new Array();
      this.getMoreVariants();
    },
    getMoreVariants() {
      let length = this.variants.length;
      const variants = this.$store.getters['variants/getMoreVariants'](length);
      this.variants = this.variants.concat(variants);
    },
    scroll() {
      window.onscroll = () => {
        const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 200;
        if (bottomOfWindow) {
          this.getMoreVariants();
        }
      };
    },
    deleteVariant(variant) {
      if (
        confirm(
          `Are you sure you want to completely delete ${variant.productNum}?\nThis action is irreversable.`
        )
      ) {
        console.log('deleted', variant._id);
        this.$store.dispatch('variants/deleteVariant', variant._id);
      }
    },
    addToProduct(variant) {
      this.product_id = variant.product_id;
      this.variant = variant;
      this.showPopup = true;
    },
    exportVariants() {
      let variants;
      if (this.selectedVariants.length != 0) {
        if (
          !confirm(
            'Items have been selected, Exporting data will export ONLY export the selected products, do you wish to continue?'
          )
        ) {
          return;
        }
        variants = this.selectedVariants;
      } else {
        this.$store.commit('setError', {
          message: 'No products have been selected',
          color: 'red',
        });
      }
      const headers =
        'Product ID,Title,Part Number,Description,Type,Flammable,Publish Product,Sale,DropShip,Local,Vendor,Tags,Categories,Subcategories,Option 1 Name,Option 1 Value,Option 2 Name,Option 2 Value,Option 3 Name,Option 3 Value,Option 4 Name,Option 4 Value,Option 5 Name,Option 5 Value,Option 6 Name,Option 6 Value,On Hand,Min Qty,MSRP,Tier 1,Tier 2,Tier 3,Tier 4,Tier 5,Tier 6,Our Cost,Whole Sale Qty,Piece Size,LB,OZ,Box Qty,BarCode,Publish Variant,Bin Location,QuickBooks ID,Product Image,Variant Image\r\n';
      let body;

      variants.forEach((variant) => {
        body += `${variant.product_id}~`;
        body += `${variant.product_name}~`;
        body += `${variant.productNum}~`;
        body += `~`;
        body += `~`;
        body += `~`;
        body += `~`;
        body += `~`;
        body += `~`;
        body += `~`;

        body += `~`;
        body += '~';
        body += '~';
        body += '~';

        for (let j = 0; j < 6; j++) {
          if (variant.options[j]) {
            body += `~`;
            body += `${variant.options[j]}~`;
          } else {
            body += '~~';
          }
        }
        body += `${variant.stock}~`;
        body += `${variant.min}~`;
        body += `${variant.msrp}~`;
        body += `${variant.tierOne}~`;
        body += `${variant.tierTwo}~`;
        body += `${variant.tierThree}~`;
        body += `${variant.tierFour}~`;
        body += `${variant.tierFive}~`;
        body += `${variant.tierSix}~`;
        body += `${variant.cost}~`;
        body += `${variant.wholeSaleQty}~`;
        body += `${variant.pieceSize ? variant.pieceSize.replace(/\n/g, '') : ''}~`;
        body += `${variant.lb}~`;
        body += `${variant.oz}~`;
        body += `${variant.boxQty}~`;
        body += `${variant.barCode ? variant.barCode.replace(/\n/g, '') : ''}~`;
        body += `${variant.publish}~`;
        body += `${variant.binLocation ? variant.binLocation.replace(/\n/g, '') : ''}~`;
        body += `${variant.quickbooksId}~`;
        body += `~`;
        body += `${variant.image}~`;
        body += '\r\n';
      });
      body = body
        .replace(/undefined/g, '')
        .replace(/,/g, '*')
        .replace(/~/g, ',')
        .replace(/null/g, '');

      const csv = headers + body;
      const fileName = 'Products ' + new Date().toLocaleString('en-US') + '.csv';
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    retrieveData(payload) {
      this.variants = new Array();
      this.$store.dispatch('variants/getVariants', payload);
    },
    selectAll() {
      if (
        !confirm('Pressing OK will select all the variants for export. Do you wish to continue?')
      ) {
        return;
      }

      this.selectedVariants = this.$store.getters['variants/sortVariants'];
      this.exportVariants();
    },
    handleButtonMethods(value) {
      switch (value.buttonMethod) {
        case 'exportAll':
          this.selectedVariants = this.$store.getters['variants/sortVariants'];
          this.exportVariants();
          break;
        case 'export':
          this.exportVariants();
          break;
        case 'retrieveUnassociated':
          this.retrieveData(`unassociated=${true}`);
          break;
        case 'cleanVariants':
          this.retrieveData(`clean=${true}`);
          break;
      }
    },
  },
  mounted() {
    this.retrieveData();
    this.scroll();
  },
  components: { Loading, ModalPopup, DualButton },
};
</script>
<style scoped></style>
